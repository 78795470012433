import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Home, Watch } from '../../pages';

interface RoutingProps {
  serverUrl: string;
}

export const Routing = ({ serverUrl }: RoutingProps): JSX.Element => {
  const [broadcastToken, setBroadcastToken] = useState('');

  return (
    <Router>
      <Switch>
        <Route
          path="/stream"
          render={() => (
            <Watch broadcastToken={broadcastToken} serverUrl={serverUrl} />
          )}
        ></Route>
        <Route
          path="/stream?*"
          render={() => (
            <Watch broadcastToken={broadcastToken} serverUrl={serverUrl} />
          )}
        ></Route>
        <Route exact path="/">
          <Home setBroadcastToken={setBroadcastToken} />
        </Route>
      </Switch>
    </Router>
  );
};
