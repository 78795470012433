import styled from 'styled-components';

import { Header } from '../header';

const PageContainer = styled.div`
  display: grid;
  grid-template-areas: 'header' 'body';
  grid-template-rows: 80px;
  max-height: 1000vh;
`;

const PageBody = styled.div`
  grid-area: body;
`;

export const Page = ({ children }) => {
  return (
    <PageContainer>
      <Header />
      <PageBody>{children}</PageBody>
    </PageContainer>
  );
};
