import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import logo from '../../assets/images/logo.png';

const HeaderContainer = styled.div`
  width: 100%;
  background-color: #d2a9d2;
  display: grid;
  grid-template-areas: 'logo title';
  grid-template-columns: 1fr 4fr;
  column-gap: 10px;
  grid-area: header;
`;

const TitleContainer = styled.div`
  grid-area: title;
  font-family: 'Rock Salt', cursive;
  margin-top: -4px;
  h1 {
    font-size: 1.5em;
    color: #6c0781;
  }
`;

const LogoContainer = styled.div`
  grid-area: logo;
  justify-self: center;
`;

const Title = () => {
  return (
    <TitleContainer>
      <h1>Vibecast</h1>
    </TitleContainer>
  );
};

const Logo = () => {
  return (
    <LogoContainer>
      <img
        style={{
          maxWidth: '60px',
          position: 'relative',
          top: '13%',
          transofrm: 'translateY(-50%)',
        }}
        src={logo}
        alt="Vibecast logo"
      ></img>
    </LogoContainer>
  );
};

export const Header = () => {
  const history = useHistory();
  const handleClick = () => history.push('/');

  return (
    <HeaderContainer onClick={handleClick}>
      <Title />
      <Logo />
    </HeaderContainer>
  );
};
