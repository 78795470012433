import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { ApolloClient, InMemoryCache } from '@apollo/client';

import { Routing } from './components/router';

// prod
// const serverUrl = "18.222.159.4:2000";
// const databseUrl = "http://18.222.159.4:4000/graphql/";

// local
const serverUrl = process.env.REACT_APP_VIEWER_SERVER_HOST;
const databseUrl = process.env.REACT_APP_STREAM_SERVER_HOST;

const client = new ApolloClient({
  uri: databseUrl,
  cache: new InMemoryCache(),
  fetchOptions: {
    mode: 'no-cors',
  },
});

function App() {
  return (
    <ApolloProvider client={client}>
      <Routing serverUrl={serverUrl} />
    </ApolloProvider>
  );
}

export default App;
