import React from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { GET_NEW_STREAM_CONFIG } from '../../queries';
import goLive from '../../assets/images/goLive.png';

const HomeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
`;

const GoLiveImg = styled.img`
  width: 100%;
  max-width: 500px;
`;

export const Home = ({ setBroadcastToken }) => {
  const history = useHistory();

  const [getNewStreamConfig, { error }] = useMutation(GET_NEW_STREAM_CONFIG, {
    onCompleted: ({ getNewStreamConfig }) => {
      console.log(getNewStreamConfig);
      setBroadcastToken(getNewStreamConfig.secretKey);
      history.push(`/stream?id=${getNewStreamConfig?.publicKey}`);
    },
  });

  if (error) console.error(error);

  const handleClick = e => {
    e.preventDefault();
    getNewStreamConfig();
  };

  return (
    <>
      <HomeContainer>
        <GoLiveImg
          src={goLive}
          alt="start a live stream"
          onClick={handleClick}
        />
      </HomeContainer>
    </>
  );
};
