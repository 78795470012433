import styled from 'styled-components';

import clipboardImage from '../../assets/images/clipboard.png';

const Instructions = styled.div`
  grid-area: text;
  justify-self: center;
  @media only screen and (max-width: 700px) {
    max-width: 350px;
  }
`;

const TokenContainer = styled.div`
  max-height: 38%;
  display: grid;
  grid-area: content;
  grid-template-areas: 'text' 'token';
  grid-template-rows: 1fr 1fr;
  justify-self: center;
  align-self: center;
  text-align: center;
`;

const InputBox = styled.div`

`;

export const Token = ({ handleClick, active, token }) => {
  if (!token) return <></>;

  return (
    <TokenContainer>
      <Instructions>
      <h3>Copy the stream key and start streaming at{' '}
        <a href="rtmp://vibecast.tech/live">rtmp://vibecast.tech/live</a>
        </h3>
      </Instructions>
      <InputBox
        onClick={handleClick}
        style={{
          gridArea: 'token',
          maxWidth: '450px',
          wordWap: 'break-word',
          display: 'grid',
          gridTemplateColumns: '250px 50px',
          columnGap: '10px',
          gridTemplateRows: '50px',
          justifySelf: 'center',
          marginRight: '11%',
        }}
      >
        <input
          style={{
            width: '100%',
            wordWap: 'break-word',
            fontSize: '20px',
          }}
          type="text"
          id="tokenBox"
          value={token}
          readOnly
        />
        <div
          style={{
            border: '1px solid black',
            width: '100%',
            paddingLeft: '30%',
            paddingTop: '1%',
            backgroundColor: '#E8E8E8',
            borderRadius: '5px',
          }}
        >
          <img
            onClick={handleClick}
            style={{
              alignSelf: 'center',
              justifySelf: 'center',
              height: '90%',
              transform: active ? 'scale(0.78)' : undefined,
              transition: '0.2s all',
            }}
            src={clipboardImage}
            alt="add to clipboard"
          />
        </div>
      </InputBox>
    </TokenContainer>
  );
};
