import styled from 'styled-components';

const ViewCountContainer = styled.div`
  background-color: rgba(255, 0, 0, 0.5);
  max-width: 150px;
  text-align: center;
  height: 30px;
  padding-top: 10px;
  border-radius: 20px;
  position: relative;
  top: -85%;
  left: 10%;
`;

export const ViewCount = ({ count, isActive }) =>
  isActive ? <ViewCountContainer>{count} viewers</ViewCountContainer> : <> </>;
