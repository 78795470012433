import React, { useState, useEffect } from 'react';
import randomstring from 'randomstring';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { HlsPlayer } from '../hlsPlayer';
import { TokenBox } from '../tokenBox';
import { ViewCount } from '../viewCount';
import {
  STREAM_HLS,
  START_VIEW,
  STOP_VIEW,
  GET_VIEW_COUNT,
} from '../../queries';
import { useQueryParams } from '../../hooks';

const userId = randomstring.generate();

export const Viewer = ({ serverUrl, broadcastToken }) => {
  const [isStreamActive, setIsStreamActive] = useState(false);
  const [viewId, setViewId] = useState('');
  const [viewCount, setViewCount] = useState(0);

  const params = useQueryParams();
  const channelName = params.get('id');
  const history = useHistory();

  const { data, error, stopPolling } = useQuery(STREAM_HLS, {
    variables: { id: channelName },
    pollInterval: 1000,
  });

  const { data: viewCountData, stopPolling: stopPollingViewCount } = useQuery(
    GET_VIEW_COUNT,
    {
      variables: { channelId: channelName },
      pollInterval: 3000,
    },
  );

  const [startView] = useMutation(START_VIEW, {
    variables: { userId, channelId: channelName },
    onCompleted: d => setViewId(d.startView.id),
  });

  const [stopView] = useMutation(STOP_VIEW, {
    variables: { id: viewId },
  });

  useEffect(() => {
    startView();
  }, [startView]);

  history.listen(location => {
    stopView();
    stopPolling();
    stopPollingViewCount();
  });

  if (
    viewCountData?.ViewCount?.count &&
    viewCountData?.ViewCount?.count !== viewCount
  ) {
    setViewCount(viewCountData?.ViewCount?.count);
  }
  if (data?.isStreamHls === true && !isStreamActive) {
    setTimeout(function(){ setIsStreamActive(true); }, 1000);
    stopPolling();
  }

  const url = `https://${serverUrl}/live/${channelName}/index.m3u8`;

  window.onunload = () => {
    stopView();
  };

  if (error) {
    stopPolling();
    stopPollingViewCount();
  }

  if (isStreamActive) {
    return (
      <>
        <div className="viewer">
          <HlsPlayer url={url} />
        </div>
        <ViewCount isActive={isStreamActive} count={viewCount} />
      </>
    );
  } else
    return (
      <>
        <TokenBox token={broadcastToken} />
      </>
    );
};
