import { gql } from '@apollo/client';

export const ACTIVE_STREAMS = gql`
  query {
    Streams(isActive: true) {
      id
      startDateTime
      endDateTime
      channelId
    }
  }
`;

export const GET_NEW_STREAM_CONFIG = gql`
  mutation {
    getNewStreamConfig {
      secretKey
      publicKey
    }
  }
`;

export const STREAM = gql`
  query ($id: String) {
    Stream(id: $id) {
      id
      startDateTime
      endDateTime
      channelId
    }
  }
`;

export const STREAM_HLS = gql`
  query ($id: String) {
    isStreamHls(id: $id)
  }
`;

export const START_VIEW = gql`
  mutation ($userId: String, $channelId: String) {
    startView(userId: $userId, channelId: $channelId) {
      userId
      startDateTime
      endDateTime
      channelId
      id
    }
  }
`;

export const STOP_VIEW = gql`
  mutation ($id: String) {
    stopView(id: $id) {
      userId
      startDateTime
      endDateTime
      channelId
    }
  }
`;

export const GET_VIEW_COUNT = gql`
  query ($channelId: String) {
    ViewCount(channelId: $channelId) {
      channelId
      count
    }
  }
`;
