import React from 'react';
import ReactPlayer from 'react-player/lazy';
import ReactHlsPlayer from 'react-hls-player';

const BaseHlsPlayer = ({ url }) => {
  const isIphone = /^((?!chrome|android).)*iPhone/i.test(navigator.userAgent);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  if (isIphone) {
    return (
      <ReactPlayer
        url={url}
        playing={true}
        controls={true}
        config={{
          file: {
            forceHLS: !isSafari,
            forceVideo: true,
          },
        }}
      />
    );
  } else {
    return (
      <div className="viewer">
        <ReactHlsPlayer
          width="100%"
          height="auto"
          url={url}
          controls={true}
          autoplay={true}
        />
      </div>
    );
  }
};

export const HlsPlayer = React.memo(BaseHlsPlayer);
