import { Page } from '../components/page';
import { Viewer } from '../components/viewer';

export const Watch = ({ broadcastToken, serverUrl }) => {
  return (
    <Page>
      <Viewer broadcastToken={broadcastToken} serverUrl={serverUrl} />
    </Page>
  );
};
