import React, { useState } from 'react';
import styled from 'styled-components';

import loadingImage from '../../assets/images/loading.gif';
import { Token } from './token';

const TokenBoxContainer = styled.div`
  display: grid;
  grid-template-areas: 'content' 'loading';
  grid-template-rows: 1fr 1fr;
`;

const LoadingImage = styled.img`
  grid-area: loading;
  justify-self: center;
  @media only screen and (max-width: 700px) {
    max-width: 400px; 
  }
`;

const LoadingImageContainer = styled.div`
  grid-area: loading;
  justify-self: center;
  align-self: start;
  margin-top: -15%;
`;

export const TokenBox = ({ token }) => {
  const handleClick = () => {
    navigator?.clipboard?.writeText(token);
    setActive(true);
    setTimeout(function () {
      setActive(false);
    }, 100);
  };
  const [active, setActive] = useState(false);

  return (
    <TokenBoxContainer>
      <LoadingImageContainer>
        <LoadingImage alt="stream loading" src={loadingImage} />
      </LoadingImageContainer>
      <Token handleClick={handleClick} token={token} active={active} />
    </TokenBoxContainer>
  );
};
