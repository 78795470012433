import { Page } from '../components/page';
import { Home as HomeComponent } from '../components/home';

export const Home = ({ setBroadcastToken }) => {
  return (
    <Page>
      <HomeComponent setBroadcastToken={setBroadcastToken} />
    </Page>
  );
};
